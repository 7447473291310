import React, { useState, useEffect } from "react";
import {
	Image,
	Text,
	View,
	TouchableOpacity,
	ScrollView,
	StyleSheet,
	Dimensions,
} from "react-native";
import { Fade } from "react-reveal";
import moment from "moment";

const { width: WINDOW_WIDTH, height: WINDOW_HEIGHT } = Dimensions.get("window");

// Product data configuration
const product1 = {
	Dry: {
		text: "ADVANCED CERAMIDE Light",
		img: require("../REVLON - EA - Ceramide Images/acl.png"),
	},
	Oily: {
		text: "ADVANCED CERAMIDE",
		img: require("../REVLON - EA - Ceramide Images/advanced.png"),
	},
	Combination: {
		text: "ADVANCED CERAMIDE",
		img: require("../REVLON - EA - Ceramide Images/advanced.png"),
	},
};

const product2 = {
	Hydration: {
		text: "HYALURONIC ACID",
		img: require("../REVLON - EA - Ceramide Images/hyl.png"),
	},
	Texture: {
		text: "RETINOL",
		img: require("../REVLON - EA - Ceramide Images/retinol.png"),
	},
	Brightness: {
		text: "VITAMIN C",
		img: require("../REVLON - EA - Ceramide Images/vitc.png"),
	},
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#FFF",
		height: "100vh",
		width: "100vw",
		overflow: "hidden",
		position: "relative",
	},
	mainContent: {
		width: "100%",
		height: "100%",
		marginHorizontal: "auto",
		padding: 20,
		paddingTop: 40,
		paddingVertical: "auto",
	},
	headerImages: {
		position: "absolute",
		height: 120,
		top: 0,
		width: "100%",
		marginBottom: 20,
		overflow: "visible",
	},
	ceramideLeft: {
		position: "fixed",
		left: -40,
		top: -40,
		width: 200,
		height: 200,
		resizeMode: "contain",
		zIndex: 1,
	},
	ceramideRight: {
		position: "fixed",
		right: -40,
		top: -40,
		width: 200,
		height: 200,
		resizeMode: "contain",
		zIndex: 1,
	},
	pageContainer: {
		height: "100%",
		width: "100%",
		padding: 20,
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		fontFamily: "Gotham",
		fontSize: 28,
		textAlign: "center",
		marginBottom: 20,
	},
	subtitle: {
		fontFamily: "Gotham",
		fontSize: 18,
		marginBottom: 8,
	},
	button: {
		padding: 12,
		borderWidth: 1,
		borderColor: "#000",
		marginVertical: 6,
		width: "100%",
		maxWidth: 600,
		alignItems: "center",
	},
	buttonText: {
		fontFamily: "Gotham",
		fontSize: 16,
	},
	selectedButton: {
		backgroundColor: "#000",
	},
	selectedButtonText: {
		color: "#FFF",
	},
	productContainer: {
		flexDirection: WINDOW_WIDTH > 600 ? "row" : "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 20,
		width: "100%",
		maxWidth: 1200,
		// marginTop: 20,
	},
	productCard: {
		alignItems: "center",
		padding: 10,
	},
	productImage: {
		width: 120,
		height: 200,
		resizeMode: "contain",
	},
	productText: {
		fontFamily: "Gotham",
		fontSize: 16,
		textAlign: "center",
		marginTop: 10,
	},
	plusSign: {
		fontSize: 30,
		margin: 10,
	},
	titleContainer: {
		width: "100%",
		alignItems: "center",
		padding: 30,
		paddingTop: 0,
	},
});

const Page = ({ children }) => (
	<Fade>
		<View style={styles.pageContainer}>{children}</View>
	</Fade>
);

const Button = ({ onPress, text, selected }) => (
	<TouchableOpacity
		onPress={onPress}
		style={[styles.button, selected && styles.selectedButton]}>
		<Text
			style={[styles.buttonText, selected && styles.selectedButtonText]}>
			{text}
		</Text>
	</TouchableOpacity>
);

const TwoAxeQuestionsUpdate = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [type, setType] = useState("");
	const [concern, setConcern] = useState("");
	const [timeoutId, setTimeoutId] = useState(null);

	useEffect(() => {
		// Store the timer reset function in a ref to maintain consistent reference
		const startTimer = () => {
			const id = setTimeout(() => {
				window.location.href = "/2axe/whatsmyceramideUpdate";
			}, 30000);
			setTimeoutId(id);
			return id;
		};

		// Initial timer start
		const id = startTimer();

		// Event handlers
		const handleActivity = () => {
			clearTimeout(id);
			startTimer();
		};

		// Add event listeners
		window.addEventListener("mousemove", handleActivity);
		window.addEventListener("keypress", handleActivity);

		// Cleanup
		return () => {
			clearTimeout(id);
			window.removeEventListener("mousemove", handleActivity);
			window.removeEventListener("keypress", handleActivity);
		};
	}, []); // Empty dependency array since we don't need to re-run this effect

	const updateAnalytics = async () => {
		const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
		if (!analyticsData) return;

		const data = {
			uuid: analyticsData.uuid,
			type,
			concern,
			date: moment().toLocaleString(),
		};

		try {
			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook/46036884-8c5d-462e-9c9e-b201bfc584de",
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				},
			);

			if (response.ok) {
				localStorage.setItem(
					"analyticsData",
					JSON.stringify({ ...analyticsData, ...data }),
				);
			}
		} catch (error) {
			console.error("Failed to update analytics:", error);
		}
	};

	const renderPage = () => {
		switch (currentPage) {
			case 1:
				return (
					<Page>
						<View style={styles.titleContainer}>
							<h1>Which Ceramide </h1>
							<h1>
								Capsule <i>is Right</i>{" "}
							</h1>
							<h1>
								for <i>You?</i>{" "}
							</h1>
						</View>
						<Button
							text="Let's begin"
							onPress={() => setCurrentPage(2)}
						/>
					</Page>
				);

			case 2:
				return (
					<Page>
						<Text style={styles.subtitle}>What's your</Text>
						<Text style={styles.title}>Skin Type?</Text>
						{["Dry", "Oily", "Combination"].map((t) => (
							<Button
								key={t}
								text={t}
								selected={type === t}
								onPress={() => {
									setType(t);
									setCurrentPage(3);
								}}
							/>
						))}
					</Page>
				);

			case 3:
				return (
					<Page>
						<Text style={styles.subtitle}>What's your</Text>
						<Text style={styles.title}>Skin Concern?</Text>
						{["Texture", "Brightness", "Hydration"].map((c) => (
							<Button
								key={c}
								text={c}
								selected={concern === c}
								onPress={() => {
									setConcern(c);
									setCurrentPage(4);
									updateAnalytics().catch(console.error);
								}}
							/>
						))}
					</Page>
				);

			case 4:
				return (
					<Page>
						<Text style={[styles.title, { marginBottom: 15 }]}>
							Your Ceramide
						</Text>
						<View style={styles.productContainer}>
							<View style={styles.productCard}>
								<Image
									source={product1[type].img}
									style={styles.productImage}
								/>
								<Text style={styles.productText}>
									{product1[type].text}
								</Text>
							</View>

							<Text style={styles.plusSign}>+</Text>

							<View style={styles.productCard}>
								<Image
									source={product2[concern].img}
									style={styles.productImage}
								/>
								<Text style={styles.productText}>
									{product2[concern].text}
								</Text>
							</View>
						</View>
						<Text
							style={[
								styles.subtitle,
								{ textAlign: "center", marginTop: 15 },
							]}>
							Available in-store & online
						</Text>
					</Page>
				);

			default:
				return null;
		}
	};

	return (
		<View style={styles.container}>
			<View style={styles.headerImages}>
				<Image
					source={require("./cer_left.png")}
					style={styles.ceramideLeft}
				/>
				<Image
					source={require("./cer_right.png")}
					style={styles.ceramideRight}
				/>
			</View>
			<View style={styles.mainContent}>{renderPage()}</View>
		</View>
	);
};

export default TwoAxeQuestionsUpdate;
