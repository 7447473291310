import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import LandingPage from "./App/LandingPage";
import Questions from "./App/Questions";
import Challenge from "./App/Questions/Challenge";
import ChallengeApp from "./App/ChallengeApp";
import JarvisChat from "./BeautyBot/JarvisChat";
import CookiePolicy from "./App/cookiePolicy";
import LandingPage2Axe from "./App/LandingPage2Axe";
import TwoAxeQuestions from "./App/2AxeQuestions";
import Cookiepolicy2 from "./BeautyBot/cookiepolicy";
import TwoAxeQuestionsUpdate from "./App/2AxeQuestions/TwoAxeQuestionsUpdate";

import HydraplayUrl from "./Quiz";
import { HydraplayBotWrapper } from "./BeautyBot/HydraplayBotUrl";
import { ARurlChatBot } from "./Ar/ARurlChatBot";
import PageTitle from "./PageTitle";
// import { LeaderboardUrlWrapper } from "./ActivationLeaderboard/LeaderboardUrl";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null,
			isLoading: true,
			isMounted: false,
			analyticsData: null,
			enterCompetition: false,
		};
	}

	componentDidMount() {
		// localStorage.clear();
	}

	render() {
		return (
			<Router>
				<Routes>
					<Route
						path='/'
						exact
						element={<LandingPage retailerUrl={""} />}
					/>
					<Route
						path='/takealot'
						exact
						element={<LandingPage retailerUrl={"takealot"} />}
					/>
					<Route
						path='/woolworths'
						exact
						element={<LandingPage retailerUrl={"woolworths"} />}
					/>
					<Route
						path='/bash'
						exact
						element={<LandingPage retailerUrl={"bash"} />}
					/>
					<Route
						path='/truworths'
						exact
						element={<LandingPage retailerUrl={"truworths"} />}
					/>
					<Route
						path='/clicks'
						exact
						element={<LandingPage retailerUrl={"clicks"} />}
					/>
					<Route
						path='/dischem'
						exact
						element={<LandingPage retailerUrl={"dischem"} />}
					/>
					<Route
						path='/edgars'
						exact
						element={<LandingPage retailerUrl={"edgars"} />}
					/>
					<Route
						path='/superbalist'
						exact
						element={<LandingPage retailerUrl={"superbalist"} />}
					/>

					<Route
						path='/whatsmyceramide'
						exact
						element={
							<>
								<PageTitle title='Whats my Ceramide' />
								<Questions />
							</>
						}
					/>

					<Route
						path='/julia/:botid'
						exact
						element={
							<>
								<PageTitle title='Elizabeth Arden - Chat Bot' />
								<JarvisChat />
							</>
						}
					/>

					<Route
						path='/hydraplayar/:retailer'
						exact
						element={
							<>
								<PageTitle title='HydraPlay™ AR' />
								<ARurlChatBot />
							</>
						}
					/>

					<Route
						path='/hydraplaybot/:botid'
						exact
						element={
							<>
								<PageTitle title='HydraPlay™ Chat bot' />
								<HydraplayBotWrapper />
							</>
						}
					/>

					<Route
						path='/hydraplayquiz/:retailer'
						exact
						element={<HydraplayUrl />}
					/>

					<Route path='/challenge' exact element={<Challenge />} />
					<Route path='/app' exact element={<ChallengeApp />} />

					<Route
						path='/cookiepolicy'
						exact
						element={<CookiePolicy />}
					/>

					<Route
						path='/2axe/'
						exact
						element={<LandingPage2Axe retailerUrl={""} />}
					/>

					<Route
						path='/2axe/whatsmyceramide'
						exact
						element={<TwoAxeQuestions />}
					/>

					<Route
						path='/2axe/whatsmyceramideUpdate'
						exact
						element={<TwoAxeQuestionsUpdate />}
					/>

					<Route
						path='/julia/cookiepolicy'
						exact
						element={<Cookiepolicy2 />}
					/>

					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
	}
}

export default App;
